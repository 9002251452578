import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import "./App.css";

const IndexPage = lazy(() => import('./pages/IndexPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const CareersPage = lazy(() => import('./pages/CareersPage'));
const NewsPage = lazy(() => import('./pages/NewsPage'));
const NewsPage020824 = lazy(() => import('./pages/News_020824'));
const PlaceholderPage = lazy(() => import('./pages/PlaceholderPage'));

const App = () => {
  return (
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <Routes>
            {/* <Route path="/" element={<PlaceholderPage />} /> */}
            <Route path="/" element={<IndexPage />} />
            <Route path="/contact" element={
              <GoogleReCaptchaProvider reCaptchaKey="6LfuIuAhAAAAAJiPfx3me_FQtY2RswUwXuCN20Cl">
                <ContactPage />
              </GoogleReCaptchaProvider>
            } />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/systemic-bio-hvios-sot-aacr-24" element={<NewsPage020824 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
  );
}

export default App;
